import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlockLink from "../components/BlockLink"
import homeImage from "../images/home.png"

const Home = styled.div`
  width: 100%;
  height: calc(100vh - 24px);
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: flex-start;
  overflow-x: hidden;

  @media (min-width: 650px) {
    padding: 0 20px 0 205px;
  }
`

const HomeImage = styled.img`
  height: 600px;
  object-fit: contain;
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 120px;
  margin-right: 30px;
  flex-shrink: 0;

  > * + * {
    margin-top: 15px;
  }
`

const HomePageTemplate = () => (
  <Home>
    <Nav>
      <BlockLink to="/artwork/">artwork</BlockLink>
      <BlockLink to="/about/">about</BlockLink>
      <BlockLink to="/contact/">contact</BlockLink>
    </Nav>
    <HomeImage src={homeImage} alt="Home" />
  </Home>
)

const HomePage = () => (
  <Layout full>
    <SEO title="Home" />
    <HomePageTemplate />
  </Layout>
)

export default HomePage
