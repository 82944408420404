import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const BlockLink = styled(Link)`
  display: inline-flex;
  color: #fff;
  font-family: Teko, sans-serif;
  background: #000;
  text-decoration: none;
  padding: 3px 20px 0px;
  align-items: center;
  line-height: 1.25;
  font-size: 26px;
  font-weight: normal;
  margin: 0;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #000;
    background: #fff;
  }
`

export default BlockLink
